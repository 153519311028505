@import '~@studyportals/styles-abstracts/abstracts.scss';

.SuggestionContainer {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	background-color: $White;
	cursor: pointer;

	@include TextStyle('Title100');
	@include PaddingAreas(0.5, 0);
}

.ShowResults {
	cursor: pointer;
	color: $Blue-700;

	@include TextStyle('BodyNarrow');

	&:hover {
		text-decoration: underline;
	}
}
