@import '~@studyportals/styles-abstracts/abstracts.scss';

.SelectedFiltersContainer {
	display: flex;
	flex-direction: column;

	.SelectedFiltersHeader {
		display: flex;
		justify-content: space-between;

		@include TextStyle('Title100');
		@include PaddingAreas(1, 0, 0.5, 0);
	}

	.FilterTagContainer {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		background-color: $White;
		@include PaddingAreas(0.5, 0);

		&.Collapsed {
			max-height: 4.5rem;
			overflow: hidden;
		}
	}

	.ShowMoreButton,
	.ShowLessButton {
		@include MarginAreas(0.5, 0, 0, 0);
	}

	.ShowMoreButton,
	.ShowLessButton,
	.ClearButton {
		width: fit-content;
		background-color: unset;
		border: none;
		cursor: pointer;

		@include TextStyle('Note');
	}

	.ButtonIcon {
		vertical-align: middle;
	}
}
