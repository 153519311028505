@import '~@studyportals/styles-abstracts/abstracts.scss';
@import '@studyportals/styles-abstracts/variables/_colors.scss';

.SuggestionSection {
	background-color: $White;
	border-radius: 0.25rem;
	@include PaddingAreas(0, 0.5);

	>*:not(:last-child) {
		border-bottom: 1px solid $Grey-200;
	}
}

.SuggestionSectionTitle {
	@include TextStyle(Title100);
}
